function Footer() {
    $ = jQuery;

    $('.js-show-footer-menu').on('click', e => {
        const clickedElement = $(e.target);
        const menuWrapper = clickedElement
            .parent()
            .find('.footer__menu-wrapper');

        if (menuWrapper.hasClass('show')) {
            menuWrapper.removeClass('show');
            return;
        } else {
            $('.footer__menu-wrapper').removeClass('show');
            menuWrapper.addClass('show');
        }
    });
}

export default Footer;
