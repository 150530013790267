import Select from './inputs/select';

function FindShop() {
    $ = jQuery;

    const root = $('[data-find-shops]');
    const redirectButton = $('.js-find-shop').parent().find('a');
    const link = redirectButton.data('src');
    const onlineShopsSelect = root.find('[data-select]');
    const seeOnlineShops = root.find('[data-find-shops-online]');
    let onlineShopsSelectInstance = null;

    initSelect();

    $('.js-find-shop').on('keyup', e => {
        if ($(e.target).val()) {
            redirectButton.attr('href', `${link}?query=${$(e.target).val()}`);
        } else {
            redirectButton.attr('href', `${link}`);
        }
    });

    seeOnlineShops.on('click', e => {
        e.preventDefault();
        if (!onlineShopsSelectInstance) return;

        const { select } = onlineShopsSelectInstance;
        const url = select.value;

        if (url === '' || !url) return;
        window.open(url, '_blank').focus();
    });

    function initSelect() {
        if (!onlineShopsSelect[0]) return;
        onlineShopsSelectInstance = new Select(onlineShopsSelect[0]);
    }
}

export default FindShop;
