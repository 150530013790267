export default function header_menu() {
    $(window).on('scroll', function () {
        if ($(window).scrollTop() > 0) {
            $('.header').addClass('header--scroll');
        } else {
            $('.header').removeClass('header--scroll');
        }
    });

    let header = $('#js-header');
    let mainMenu = $('.js-open-menu');
    let navigation = $('.navigation--mobile');
    let headerTabText = $('.js-header-mobile-title');

    $('#js-burger').on('click', function () {
        navigation.toggleClass('navigation--mobile__open');
        headerTabText.removeClass('active');
        $('#js-menu').toggleClass('navigation-menu-container--open');
        $('#js-burger').toggleClass('is-active');
        $('.menu-item-has-children').removeClass(
            'main-menu__item--mobile-active'
        );
        $('.header__brand').removeClass('header__brand--submenu-active');
        $('.saved-products').removeClass('mobile-active');
        $('.header-search').removeClass('mobile-active');
        $('.header__menus').removeClass('block');
        $('.megamenu').removeClass('block');
        header.toggleClass('is-active');
        mainMenu.toggleClass('header__menus--mobile');
    });

    $('.main-menu__item--depth-1').on('click', function () {
        $('#js-burger').trigger('click');
    });

    $('.main-menu__item--depth-0 > a').on('click', function (e) {
        if($(e.target).data('megamenu') || $(e.target).parent().hasClass('menu-item-has-children')) {
            if (window.innerWidth < 1200) {
                e.preventDefault();
            }
        }
    });

    $('.menu-item-has-children > a').on('click', e => {
        $('.header__mobile-title').text($(e.target).text());
        $('.header__brand').addClass('header__brand--submenu-active');
        $('.header__mobile-title').addClass('active');
        $(e.currentTarget)
            .parent()
            .toggleClass('main-menu__item--mobile-active');
    });
    $('.js-show-sub-items').on('click', e => {
        $(e.currentTarget)
            .parent()
            .parent()
            .find('.megamenu-list__content')
            .addClass('mobile-active');
        $('.header__mobile-title').text($(e.target).data('name'));
        $('.megamenu__mobile-link').removeClass('active');
        $('.megamenu').animate(
            {
                scrollTop: 0,
            },
            0
        );
        $('.megamenu').addClass('block');
        $(e.currentTarget)
            .parent()
            .parent()
            .find('.megamenu__mobile-link')
            .addClass('active');
    });

    $('.header__mobile-title').on('click', e => {
        $('#js-burger').trigger('click');
    });

    // Pokazywanie zapisanych produktów

    $('.js-show-saved-products').hover(e => {
        e.preventDefault();
        hideElement('.header-search');
        $('.saved-products').addClass('active');
    });

    $('main').hover(e => {
        hideElement('.saved-products');
        hideElement('.header-search');
    });

    $('.js-hide-right-elements').hover(e => {
        hideElement('.saved-products');
        hideElement('.header-search');
    });

    const hideElement = element => {
        $(element).removeClass('active');
    };

    $('.js-show-saved-products').on('click', e => {
        hideBrandShowText($(e.target));
        e.preventDefault();
        $('.saved-products').toggleClass('mobile-active');
        $('.saved-products__mobile-link ').addClass('active');
    });

    // Pokazywanie wyszukiwarki
    $('.js-show-search').hover(e => {
        e.preventDefault();
        hideElement('.saved-products');
        $('.header-search').addClass('active');
    });

    $('.js-show-search').on('click', e => {
        hideBrandShowText($(e.target));
        $('.header-search').addClass('mobile-active');
    });
    let currentSubmenu = '';
    $('.menu-item-has-children.main-menu__item--depth-0').hover((e) => {
        currentSubmenu = $(e.currentTarget).find('.main-menu__submenu');
        if (!$('.header__submenu-placeholder').hasClass('active')) {
            setTimeout(() => {
                currentSubmenu.addClass('active');
                $('.header__submenu-placeholder').addClass('active');
            }, 200)
        } else {
            $('.header__submenu-placeholder').css('height', 0);
            currentSubmenu.removeClass('active');
            $('.header__submenu-placeholder').removeClass('active');
        }
        const submenuHeight = $(e.currentTarget).find('.main-menu__submenu').outerHeight(true);
        $('.header__submenu-placeholder').css('height', submenuHeight);
    })
    $('.menu-item-has-children.main-menu__item--depth-0').on('mouseleave', e => {
        setTimeout(() => {
            if ($('.header__submenu-placeholder').hasClass('active')) {
                $('.header__submenu-placeholder').removeClass('active');
                currentSubmenu.removeClass('active');
            } 
        }, 200)
    });
    $('.header__submenu-placeholder').hover((e) => {
        $('.header__submenu-placeholder').toggleClass('active');
        currentSubmenu.toggleClass('active');
    })

    const hideBrandShowText = target => {
        $('.header__mobile-title').text(target.text());
        $('.header__brand').addClass('header__brand--submenu-active');
        $('.header__mobile-title').addClass('active');
    };
}
