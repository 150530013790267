function megamenu() {
    $ = jQuery;

    $('.main-menu__item').on('mouseover', e => {
        hideMegamenu();
    });

    $('.js-has-megamenu').on('mouseover', e => {
        hideMegamenu();
        const megamenuClass = $(e.currentTarget)
            .find('a')
            .data('megamenu')
            .replace('megamenu-', '');
        $(`.megamenu--${megamenuClass}`).addClass('active');
    });

    $('.js-has-megamenu').on('click', e => {
        hideMegamenu();
        $('.header__mobile-title').text($(e.target).text());
        $('.header__brand').addClass('header__brand--submenu-active');
        $('.header__mobile-title').addClass('active');
        const megamenuClass = $(e.currentTarget)
            .find('a')
            .data('megamenu')
            .replace('megamenu-', '');
        $(`.megamenu--${megamenuClass}`).addClass('mobile-active');
        $(`.megamenu--${megamenuClass}`)
            .find('.megamenu__mobile-link')
            .first()
            .addClass('active');
    });

    $('.megamenu').on('mouseleave', e => {
        hideMegamenu();
    });

    $('main').on('mouseover', e => {
        hideMegamenu();
    });

    // $('main').hover( (e) => {
    //     hideMegamenu();
    // })

    $('.js-hide-megamenu').on('mouseover', e => {
        hideMegamenu();
    });

    const hideMegamenu = () => {
        $('.megamenu').removeClass('active');
        $('.megamenu').removeClass('mobile-active');
        $('.megamenu-list__content').removeClass('mobile-active');
        $('.megamenu__mobile-link').removeClass('active');
        $('.megamenu-list__third-items').removeClass('show');
    };

    $('.js-show-third-items').on('click', e => {
        e.preventDefault();

        const clickerArrow = $(e.currentTarget);
        const targetThirdItems = clickerArrow
            .parent()
            .next('.megamenu-list__third-items');

        if (targetThirdItems.hasClass('show')) {
            targetThirdItems.removeClass('show');
            clickerArrow.removeClass('rotate');
            return;
        } else {
            $('.megamenu-list__third-items').removeClass('show');
            $('.js-show-third-items').removeClass('rotate');
            targetThirdItems.addClass('show');
            clickerArrow.addClass('rotate');
        }
    });
}

export default megamenu;
