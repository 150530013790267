import Aos from 'aos';
import FindShop from './modules/FindShop';
import Footer from './modules/Footer';
import header_menu from './modules/header';
import megamenu from './modules/megamenu';
import productList from './modules/products-list';
import saveProducts from './modules/saveProducts';
import SingleProduct from './modules/SingleProduct';
import productTables from './modules/product-tables';
import cookies from './modules/cookies';
import { Collapse } from 'bootstrap';
const documentReady = function () {
    // Collapse = new Collapse();
    SingleProduct();
    FindShop();
    Footer();
    productList();
    header_menu();
    megamenu();
    saveProducts();
    productTables();
    cookies();

    Aos.init({
        disable: 'mobile',
        anchorPlacement: 'top-bottom',
        once: true,
    });
};

if (
    document.readyState === 'complete' ||
    (document.readyState !== 'loading' && !document.documentElement.doScroll)
) {
    documentReady();
} else {
    document.addEventListener('DOMContentLoaded', documentReady);
}
