class CookieBar {
    constructor(el) {
        if (!this.set(el)) return;
        this.init();
    }

    set(el) {
        if (!el) return false;
        this.root = el;
        this.button = this.root.querySelector('[data-cookies-button]');

        console.log(this.root, this.button);

        return true;
    }

    init() {
        this.setButton();
    }

    setButton() {
        if (!this.button) return;
        this.onButtonSubmit = this.onSubmit.bind(this);
        this.button.addEventListener('click', this.onButtonSubmit);
    }

    onSubmit() {
        document.cookie = `cookie_accept=${true}; expires=${new Date(new Date().getTime()+1000*60*60*24*365).toGMTString()}; path=/`;
        this.root.classList.add('d-none');
    }
}

export default function cookies() {
    const cookieBar = document.querySelector('[data-cookies]');
    if (cookieBar) new CookieBar(cookieBar);
}