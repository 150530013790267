import debounce from 'lodash.debounce';
import noUiSlider from 'nouislider';
import Pagination from './pagination/pagination';

export default function productList() {
    $ = jQuery;
    if ($('.products-listing').first().length == 0) return;

    const root = $('.products-listing').first();
    const results = $('.products-results').first();
    const lang = root.children('#currLang').val();
    const initPage = root.children('#initPage').val();
    const category = root.children('#productsListingCat').val();
    const settings = JSON.parse(root.attr('data-settings'));
    const paginationEl = root.find('.pagination');
    const notFound = root.find('.products-listing__notFound');
    const tabsToggle = root.find('.products-filters__filtersToggle');
    const filterTabs = root.find('.products-filters__filtersTabs');
    const filterSearchInputs = root.find('.products-filters__filterSearchInput');
    const allInputs = root.find('.products-filters__filterOption input[data-name]');

    let activeFacetKey = '';
    let activeFacetName = '';
    let activeFacetField = '';
    let activeFacets = [];
    let activeFacetsNames = [];
    let facets = [];
    let pagination = null;
    let maxPages = 0;
    let initLoad = false;

    let seoCheckboxesActive = [];
    let seoTitle = root.find('[data-product-page-seo-title]').val();
    let seoDescription = root.find('[data-product-page-seo-description]').val();

    const seoDescriptionWrapper = root.find('.products-listing__description');
    const seoTitleEl = root.find('.products-listing__descriptionTitleEl');
    const seoDescriptionEl = root.find('.products-listing__descriptionTextEl');
    const loader = $('.products-listing__resultsLoader');
    const filters = $('.products-filters').first();
    const filterToggle = $('.products-listing__toggle').first();
    const filterClose = $('.products-filters__filtersClose').first();
    const rangeSliders = $('.products-filters__filterSliderUI');
    const rangesSlidersData = [];

    const checkboxes = $('.products-filters input[type="checkbox"]');
    const minRanges = $('.products-filters__filterRangeMin');
    const maxRanges = $('.products-filters__filterRangeMax');

    let currentPage = 1;
    let updateRange = false;
    let mode = 'paging';
    let products = [];
    let predefined_options = [];
    let template = '';

    if (initPage !== '') currentPage = parseInt(initPage);

    function splitFirstOccurrence(str, separator) {
        const [first, ...rest] = str.split(separator);
        const remainder = rest.join('-');

        return { first, remainder };
    }

    const filterProducts = debounce(function () {
        let allInputsItems = [];
        let metaFilters = [];
        let allRanges = [];
        let checkboxFilters = [];
        let minRangesFilters = [];
        let maxRangesFilters = [];
        activeFacets = [];
        activeFacetsNames = [];
        const formData = new FormData();

        formData.append('action', 'products_list');
        formData.append('lang', lang);
        formData.append('category', category);
        formData.append('per_page', settings.perPage);
        formData.append('current_page', currentPage);

        checkboxes.each(function () {
            const el = $(this);
            const name = el.attr('name');
            const key = el.attr('data-key');
            const checked = el.is(':checked');
            let value = el.val();
            let slug = el.attr('data-name');
            let groupData = el.attr('data-groups-data');
            const meta = el.attr('data-meta');

            if (meta && meta !== '') metaFilters.push(meta);
            if (checked) {
                if (groupData) {
                    groupData = JSON.parse(groupData);
                    const parentIds = groupData.map(item => item.parent);
                    const activeParentIds = [];
    
                    parentIds.forEach(parent => {
                        const parentCheck = $(`input[value="${parent}"]`);
                        if (parentCheck.is(':checked')) activeParentIds.push(parent);
                    });
    
                    const activeGroups = groupData.filter(item => activeParentIds.includes(item.parent));
                    if (activeGroups.length > 0) {
                        let groupValues = activeGroups.map(item => item.term_id);
                        let groupSlugs = activeGroups.map(item => item.slug);

                        value = groupValues.join(',');
                        slug = groupSlugs.join(',');
                    }
                }

                checkboxFilters.push({
                    key,
                    value,
                    slug,
                });

                formData.append(name, value);
            }
        });

        checkboxes.each(function() {
            const el = $(this);
            const field = el.attr('data-field');
            const name = el.attr('name');
            const key = el.attr('data-key');
            const keyCheckboxes = $(`[data-key="${key}"]`);

            keyCheckboxes.each(function() {
                const keyChk = $(this);
                if (keyChk.is(':checked')) {
                    activeFacets.push({
                        key,
                        field,
                        name
                    });
                }
            });
        });

        metaFilters = [...new Set(metaFilters)];
        activeFacetsNames = [... new Set(activeFacets.map(item => item.name.replace('[]', '')))];
        activeFacets = [... new Set(activeFacets.map(item => item.key))];
        activeFacetKey = (activeFacets.length > 0) ? activeFacets[activeFacets.length - 1] : '';

        const activeField = (activeFacetsNames.length > 0) ? activeFacetsNames[activeFacetsNames.length - 1] : '';
        if (activeField !== '') formData.append('active_facet_key', activeField);
        if (metaFilters) formData.append('meta_filters', JSON.stringify(metaFilters));

        minRanges.each(function () {
            const el = $(this);
            const key = el.attr('data-key');
            const name = el.attr('name');
            const value = el.val();

            if (value !== '') {
                minRangesFilters.push({
                    key,
                    name: name,
                    value,
                });
                formData.append(name, value);
            }
        });

        maxRanges.each(function () {
            const el = $(this);
            const key = el.attr('data-key');
            const name = el.attr('name');
            const value = el.val();

            if (value !== '') {
                maxRangesFilters.push({
                    key,
                    name: name,
                    value,
                });
                formData.append(name, value);
            }
        });

        if (!checkboxes && !minRanges && !maxRanges) {
            predefined_options.forEach(opt => {
                const { name, values } = opt;
                values.forEach(val => {
                    formData.append(`${name}[]`, val);
                });
            });
        }

        allInputs.each(function() {
            const item = $(this);
            let name = item.attr('name');
            const key = item.attr('data-key');
            const value = item.val();
            const meta = item.attr('data-meta');
            name = name.replace('[]', '');
        
            if (!meta || meta === '') {
                allInputsItems.push({
                    key,
                    name,
                    value
                });
            }
        });
        formData.append('filters', JSON.stringify(allInputsItems));

        rangeSliders.each(function() {
            const item = $(this);
            const key = item.attr('data-key');
            allRanges.push(key);
        });

        formData.append('ranges', JSON.stringify(allRanges));

        if (initLoad)
            appendToURL(checkboxFilters, minRangesFilters, maxRangesFilters);

        $.ajax({
            type: 'POST',
            url: ajax.url,
            enctype: 'multipart/form-data',
            processData: false,
            contentType: false,
            data: formData,
            beforeSend: function () {
                loader.addClass('active');
            },
            success: function (response) {
                const data = JSON.parse(response);
                const { maxNumPages, paged, faced_filters, ranges_filters, all_facets, meta_facets } = data;
                maxPages = maxNumPages;
                products = data.data;

                results.html(products);
                pagination.reset(paged, maxPages);

                const activeFacetKeys = Object.keys(all_facets);
                const filteredFacets = faced_filters.filter(item => !activeFacetKeys.includes(item.field));

                Object.keys(all_facets).forEach((item, key) => {
                    const activeAvailableFacet = all_facets[item];

                    activeAvailableFacet.forEach(filter => {
                        let { value, visible } = filter;
                        const input = root.find(`input[value="${value}"]`).parent();

                        if(visible) {
                            input.removeClass('hidden');
                        } else {
                            input.addClass('hidden');
                        }
                    });
                });

                filteredFacets.forEach(filter => {
                    let { value, visible } = filter;
                    const input = root.find(`input[value="${value}"]`).parent();

                    if(visible) {
                        input.removeClass('hidden');
                    } else {
                        input.addClass('hidden');
                    }
                });

                Object.keys(meta_facets).forEach((item, key) => {
                    const metaValues = meta_facets[item];
                    const inputs = root.find(`input[data-meta="${item}"]`);

                    inputs.each(function() {
                        const input = $(this);
                        const parent = input.parent();
                        const value = input.val().replaceAll("'", '');

                        if (metaValues.includes(value)) {
                            parent.removeClass('hidden');
                        } else {
                            parent.addClass('hidden');
                        }
                    });
                });

                if (updateRange) {
                    ranges_filters.forEach(range => {
                        const { name, min, max } = range;
                        const rangeData = rangesSlidersData[name];
                        if (rangeData) {
                            const { el, slider, minEl, maxEl } = rangeData;
                            if (min && max) {
                                slider.updateOptions({
                                    start: [min, max],
                                    range: {
                                        min: min,
                                        max: max,
                                    },
                                });
                                el.attr('data-range-min', min);
                                el.attr('data-range-max', max);
                                el.attr('data-init-min', min);
                                el.attr('data-init-max', max);
                                el.closest('.products-filters__filter').removeClass('disabled');
    
                                minEl.attr('placeholder', min);
                                maxEl.attr('placeholder', max);
                                minEl.val("");
                                maxEl.val("");
                            } else {
                                el.closest('.products-filters__filter').addClass('disabled');
                            }
                        }
                    });
                }
                facets = faced_filters;

                if (products.length === 0) {
                    notFound.addClass('show');
                    pagination.hidePagination();
                } else if (products.length > 0) {
                    notFound.removeClass('show');
                    if (maxPages > 1) pagination.showPagination();
                } else {
                    notFound.removeClass('show');
                }
            },
            error: function () {
                products = [];
                notFound.addClass('show');
                pagination.hidePagination();
            },
            complete: function () {
                loader.removeClass('active');
                updateRange = true;
                initLoad = true;
            },
        });
    }, 200);

    initFiltersUI();
    initRangeSliders(rangeSliders);
    initPagination();
    initInputs();
    initSearchInputs();
    initResetFilters();
    filterProducts();

    function appendToURL(checkboxFilters, minRangesFilters, maxRangesFilters) {
        let appendURL = '';
        let stringsURL = [];

        checkboxFilters = checkboxFilters.reduce((result, current) => {
            (result[current['key']] = result[current['key']] || []).push(
                current
            );
            return result;
        }, {});

        if (Object.keys(checkboxFilters).length !== 0) {
            Object.entries(checkboxFilters).forEach(item => {
                const key = item[0];
                let values = item[1].map(val => {
                    const temp = splitFirstOccurrence(val.slug, '-');
                    return temp.remainder;
                });
                values = values.join(',');
                const stringURL = `${key}=${values}`;
                stringsURL.push(stringURL);
            });
        }

        minRangesFilters.forEach(item => {
            const { key, value } = item;
            const stringURL = `${key}=${value}`;
            stringsURL.push(stringURL);
        });
        maxRangesFilters.forEach(item => {
            const { key, value } = item;
            const stringURL = `${key}=${value}`;
            stringsURL.push(stringURL);
        });

        if (currentPage > 1) stringsURL.push(`strona=${currentPage}`);
        if (stringsURL.length > 0) {
            appendURL = `${settings.baseURL}?${stringsURL.join('&')}`;
            window.history.replaceState(settings.baseURL, null, appendURL);
        } else {
            appendURL = `${settings.baseURL}`;
            window.history.replaceState(settings.baseURL, null, appendURL);
        }
    }

    function initFiltersUI() {
        filterToggle.click(function () {
            filters.toggleClass('active');
        });

        filterClose.click(function () {
            filters.removeClass('active');
        });

        if (tabsToggle) {
            tabsToggle.click(function () {
                $(this).toggleClass('active');
                filterTabs.toggleClass('active');
            });
        }
    }

    function initInputs() {
        checkboxes.each(function () {
            const checkbox = $(this);
            const name = checkbox.attr('name');
            const slug = checkbox.attr('data-name');

            if (name === 'products_types[]' || name === 'doors[]' || name === 'paddling[]') {
                if (checkbox.is(':checked')) {
                    seoCheckboxesActive.push(checkbox);
                } else {
                    seoCheckboxesActive.forEach((item, index) => {
                        let seoChkSlug = item.attr('data-name');
                        if (seoChkSlug === slug) seoCheckboxesActive.splice(index, 1);
                    });
                }
            }
        });

        checkboxes.change(function () {
            const checkbox = $(this);
            const name = checkbox.attr('name');
            const slug = checkbox.attr('data-name');
            const field = checkbox.attr('data-field');
            const key = checkbox.attr('data-key');
            const keyCheckboxes = $(`[data-key="${key}"]`);
            let activeSeoTitle = '';
            let activeSeoDesc = '';

            keyCheckboxes.each(function() {
                const keyChk = $(this);
                if (keyChk.is(':checked')) {
                    activeFacetKey = key;
                    activeFacetName = name;
                    activeFacetField = field;
                }
            });

            if (name === 'products_types[]' || name === 'doors[]' || name === 'paddling[]') {
                if (checkbox.is(':checked')) {
                    seoCheckboxesActive.push(checkbox);
                } else {
                    seoCheckboxesActive.forEach((item, index) => {
                        let seoChkSlug = item.attr('data-name');
                        if (seoChkSlug === slug) seoCheckboxesActive.splice(index, 1);
                    });
                }
            }
    
            seoCheckboxesActive.forEach(item => {
                activeSeoTitle = item.attr('data-pr-category-title');
                activeSeoDesc = item.attr('data-pr-category-description');
            });
    
            if (activeSeoDesc !== '') {
                seoTitleEl.html(activeSeoTitle);
                seoDescriptionEl.html(activeSeoDesc);
                seoDescriptionWrapper.show();
            } else {
                seoTitleEl.html('');
                seoDescriptionEl.html('');
                seoDescriptionWrapper.hide();
            }
    
            if (seoCheckboxesActive.length > 1) {
                seoTitleEl.html('');
                seoDescriptionEl.html('');
                seoDescriptionWrapper.hide();
            }
    
            if (seoCheckboxesActive.length === 0) {
                seoTitleEl.html(seoTitle);
                seoDescriptionEl.html(seoDescription);

                if (seoDescription === '') {
                    seoDescriptionWrapper.hide();
                } else {
                    seoDescriptionWrapper.show();
                }
            }

            updateRange = true;
            currentPage = 1;
            filterProducts();
        });
    }

    function initSearchInputs() {
        filterSearchInputs.on('input', function(event) {
            const el = $(this);
            const text = el.val().toLowerCase();

            const search = el.parent();
            const wrapper = search.next();
            const options = wrapper.find('.products-filters__filterOption');
            
            options.each(function() {
                const option = $(this);
                const optionText = option.attr('data-filter-option').toLowerCase();
                const input = option.find('input');
                input.prop("checked", false);

                if (text === '') {
                    option.show();
                } else {
                    if (optionText.includes(text)) option.show();
                    else option.hide();
                }
            })

            filterProducts();
        });
    }

    function initPagination() {
        if (!paginationEl) return;
        pagination = new Pagination(paginationEl[0]);
        pagination.hidePagination();

        $(window).on('pagination-change', function (event) {
            const { originalEvent } = event;
            const { detail } = originalEvent;
            const scrollEl = document.getElementById('products-listing-main');

            currentPage = detail;
            filterProducts();

            scrollEl.scrollIntoView({
                behavior: 'smooth',
            })
        });
    }

    function initRangeSliders(sliders) {
        if (!sliders) return;

        sliders.each(function () {
            const el = $(this).get();
            const key = $(this).attr('data-key');
            const min = parseInt(el[0].getAttribute('data-range-min'));
            const max = parseInt(el[0].getAttribute('data-range-max'));
            const initMin = parseInt(el[0].getAttribute('data-init-min'));
            const initMax = parseInt(el[0].getAttribute('data-init-max'));

            const minRangeEl = $(this)
                .parent()
                .find('.products-filters__filterRangeMin');
            const maxRangeEl = $(this)
                .parent()
                .find('.products-filters__filterRangeMax');

            const slider = noUiSlider.create(el[0], {
                start: [min, max],
                step: 10,
                connect: true,
                range: {
                    min: min,
                    max: max,
                },
            });

            if (initMin && initMax) slider.set([initMin, initMax], false);

            slider.on(
                'slide',
                function (
                    values,
                    handle,
                    unencoded,
                    tap,
                    positions,
                    noUiSlider
                ) {
                    const { target } = slider;
                    const minEl = target.parentNode.querySelector(
                        '.products-filters__filterRangeMin'
                    );
                    const maxEl = target.parentNode.querySelector(
                        '.products-filters__filterRangeMax'
                    );

                    if (minEl) minEl.value = values[0].split('.')[0];
                    if (maxEl) maxEl.value = values[1].split('.')[0];

                    currentPage = 1;
                    updateRange = false;
                    filterProducts();
                }
            );

            if (minRangeEl) {
                if (initMin) minRangeEl.val(initMin);

                minRangeEl.bind(
                    'keyup change',
                    debounce(function () {
                        onRangeInputChange(minRangeEl, 'min', slider);
                    }, 200)
                );
            }

            if (maxRangeEl) {
                if (initMax) maxRangeEl.val(initMax);

                maxRangeEl.bind(
                    'keyup change',
                    debounce(function () {
                        onRangeInputChange(maxRangeEl, 'max', slider);
                    }, 200)
                );
            }

            rangesSlidersData[key] = {
                el: $(this),
                slider,
                minEl: minRangeEl,
                maxEl: maxRangeEl,
            }
        });

        $('[data-range-reset]').click(function(event) {
            event.preventDefault();
            const button = $(this);
            const key = button.attr('data-range-reset');

            if (!key || key === '') return;
            const rangeSlider = rangesSlidersData[key];
            const { el, slider, minEl, maxEl } = rangeSlider;
            const min = el.attr('data-range-min');
            const max = el.attr('data-range-max');
            const startMin = el.attr('data-start-min');
            const startMax = el.attr('data-start-max');

            slider.updateOptions({
                start: [min, max],
            });

            el.attr('data-range-min', startMin);
            el.attr('data-range-max', startMax);
            minEl.attr('placeholder', startMin);
            maxEl.attr('placeholder', startMax);
            minEl.val("");
            maxEl.val("");

            filterProducts();
        });
    }

    function onRangeInputChange(input, type, slider) {
        let value = parseInt(input.val());
        const { options } = slider;
        const { range } = options;
        const { min, max } = range;

        if (value === 0) return;
        if (type === 'min' && (value < min || value > max)) {
            input.val(min);
            value = min;
        }
        if (type === 'max' && (value > max || value < min)) {
            input.val(max);
            value = max;
        }

        if (type === 'min') slider.set([parseInt(value), null]);
        if (type === 'max') slider.set([null, parseInt(value)]);

        filterProducts();
    }

    function initResetFilters() {
        root.find('.products-filters__filtersClear').on('click', function(event) {
            event.preventDefault();
            
            checkboxes.each(function () {
                const el = $(this);
                el.prop('checked', false);
            });

            Object.entries(rangesSlidersData).forEach(item => {
                const rangeSlider = item[1];
                const { el, slider, minEl, maxEl } = rangeSlider;
                const min = el.attr('data-range-min');
                const max = el.attr('data-range-max');
                const startMin = el.attr('data-start-min');
                const startMax = el.attr('data-start-max');

                slider.updateOptions({
                    start: [min, max],
                });

                el.attr('data-range-min', startMin);
                el.attr('data-range-max', startMax);
                minEl.attr('placeholder', startMin);
                maxEl.attr('placeholder', startMax);
                minEl.val("");
                maxEl.val("");
            })

            filterProducts();
        });
    }
}
