import productTable from "./tables/productTable";

function saveProducts() {
    $ = jQuery;

    const saveButton = $('.js-save-product');

    function getCookie(name) {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(';').shift();
    }

    const refreshSavedProducts = () => {
        $.ajax({
            type: 'post',
            url: ajax.url,
            data: {
                action: 'refresh_saved_products',
            },
            beforeSend: function () {
                $('.saved-products__loader-wrapper').fadeIn();
            },
            success: function (response) {
                const data = JSON.parse(response);
                $('.js-saved-products-wrapper').html(data.data);
                $('.saved-products__loader-wrapper').fadeOut();
            },
        });
    };

    const refreshDimensionTable = () => {
        const dimensionsTable = $('.single-products-accordion__table');

        if (!dimensionsTable.length) {
            return;
        }

        $.ajax({
            type: 'post',
            url: ajax.url,
            data: {
                action: 'refresh_dimensions_table',
                postParent: parseInt($('.js-post-id').data('post')),
            },
            beforeSend: function () {},
            success: function (response) {
                const data = JSON.parse(response);
                $('.js-dimensions-table-wrapper').html('');
                $('.js-dimensions-table-wrapper').html(data.data);
                const table = $('.js-dimensions-table-wrapper').find('[data-product-table]');
                new productTable(table[0]);
            },
        });
    };

    const expiresFunction = () => {
        let d = new Date();
        d.setTime(d.getTime() + 365 * 24 * 60 * 60 * 1000);
        const expires = 'expires=' + d.toUTCString();
        return expires;
    };

    const getCookieSavedProducts = () => {
        const productsArray = JSON.parse(getCookie('savedProducts'));
        return productsArray;
    };

    const removeSavedProduct = (productId, productsArray) => {
        const expires = expiresFunction();
        const filteredArray = productsArray.filter(item => item !== productId);
        document.cookie =
            'savedProducts' +
            '=' +
            JSON.stringify(filteredArray) +
            '; ' +
            expires +
            '; path=/';
        refreshDimensionTable();
    };

    const removeAllSavedProducts = () => {
        const expires = expiresFunction();
        document.cookie =
            'savedProducts' +
            '=' +
            JSON.stringify([]) +
            '; ' +
            expires +
            '; path=/';
        $('.saved-products');
        refreshDimensionTable();
    };

    $(document).on('click', '.js-save-product', e => {
        e.preventDefault();
        const clickedElement = $(e.currentTarget);
        const productId = clickedElement.data('id');
        const buttonAddText = clickedElement.data('add');
        const removeText = clickedElement.data('remove');

        const expires = expiresFunction();

        // if sprawdzający czy localstorage savedProducts istnieje
        if (!getCookie('savedProducts')) {
            document.cookie =
                'savedProducts' +
                '=' +
                JSON.stringify([productId]) +
                '; ' +
                expires +
                '; path=/';
            // localStorage.setItem('savedProducts', JSON.stringify([productId]));
            clickedElement.text(removeText);
        } else {
            const productsArray = getCookieSavedProducts();
            if (!productsArray.includes(productId)) {
                productsArray.push(productId);
                document.cookie =
                    'savedProducts' +
                    '=' +
                    JSON.stringify(productsArray) +
                    '; ' +
                    expires +
                    '; path=/';
                clickedElement.text(removeText);
            } else {
                removeSavedProduct(productId, productsArray);
                clickedElement.text(buttonAddText);
            }
        }

        refreshSavedProducts();
    });

    $(document).on('click', '.js-remove-saved-product', e => {
        e.preventDefault();
        const productId = $(e.currentTarget).data('id');
        const productsArray = getCookieSavedProducts();
        removeSavedProduct(productId, productsArray);
        refreshSavedProducts();
    });

    $(document).on('click', '.js-remove-all-saved', e => {
        e.preventDefault();
        removeAllSavedProducts();
        refreshSavedProducts();
    });
}

export default saveProducts;
