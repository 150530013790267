export default class productTable {
    constructor(el) {
        if (!this.set(el)) return;
        this.init();
    }

    set(el) {
        if (!el) return false;
        this.table = el;
        this.cols = this.table.querySelectorAll('[data-product-table-col]');
        this.emptyCells = [];

        return true;
    }

    init() {
        this.initCols();
        this.checkEmptyCells();
    }

    initCols() {
        if (!this.cols) return;

        this.cols.forEach(col => {
            const name = col.getAttribute('data-product-table-col');
            const cells = this.table.querySelectorAll(
                `[data-product-table-cell="${name}"]`
            );
            const cellsCount = cells.length;
            let emptyCount = 0;

            cells.forEach(cell => {
                const spanEl = cell.querySelector('span');
                const text = spanEl.innerHTML;

                if (text === '-') emptyCount++;
            });

            if (emptyCount === cellsCount && emptyCount > 0) {
                this.emptyCells.push(name);
            };
        });

        this.emptyCells = [...new Set(this.emptyCells)];
    }

    checkEmptyCells() {
        this.emptyCells.forEach(empty => {
            const col = this.table.querySelectorAll(
                `[data-product-table-col="${empty}"]`
            );
            const cells = this.table.querySelectorAll(
                `[data-product-table-cell="${empty}"]`
            );

            col.forEach(c => c.remove());
            cells.forEach(cell => cell.remove());
        });
    }
}
