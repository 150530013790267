import simpleLightbox from 'simplelightbox';
import Swiper, { Navigation, Thumbs } from 'swiper';

function SingleProduct() {
    $ = jQuery;

    // Lightboxes

    const imagesLightbox = new simpleLightbox(
        '.single-products-gallery__images a',
        {
            /* options */
        }
    );
    const detailsLightbox = new simpleLightbox(
        '.single-products-info__details a',
        {
            /* options */
        }
    );
    const kindsLightbox = new simpleLightbox(
        '.single-products-accordion-kinds__gallery a',
        {
            /* options */
        }
    );

    const accordionDescriptionGallery = document.querySelector('.accordion__description-fields-gallery');
    if (accordionDescriptionGallery) {
        const accordionDescriptionGalleryLightbox = new simpleLightbox(
            '.accordion__description-fields-gallery a',
            {
                /* options */
            }
        );
    }


    new simpleLightbox(`.js-lightbox-table a`, {
        /* options */
    })
    // Swipers

    Swiper.use([Thumbs, Navigation]);

    const swiperThumb = new Swiper('.single-products-gallery__thumbnails', {
        spaceBetween: 10,
        slidesPerView: 4,
        freeMode: true,
        watchSlidesProgress: true,
    });

    const swiperGallery = new Swiper('.single-products-gallery__images', {
        spaceBetween: 10,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        thumbs: {
            swiper: swiperThumb,
        },
    });

    const similarProducts = new Swiper(
        '.single-products-similar-products__list',
        {
            slidesPerView: 1.2,
            spaceBetween: 24,
            loop: true,

            breakpoints: {
                576: {
                    slidesPerView: 2,
                },
                992: {
                    slidesPerView: 3.5,
                    spaceBetween: 40,
                },
                1200: {
                    slidesPerView: 4,
                    spaceBetween: 40,
                },
                1600: {
                    slidesPerView: 6,
                    spaceBetween: 24,
                },
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
        }
    );
}

export default SingleProduct;
